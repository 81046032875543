import React, {Component} from 'react';
import axios from "axios";
import IssueTable from "./TableComponent";
import {
    Box, Button,
    Card,
    CardActions,
    CardContent,
    Collapse,
    IconButton,
    Link,
    styled,
    TextField
} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function ExpandMoreIcon() {
    return <ArrowDownward/>;
}

class BacklogIssueTable extends Component {
    constructor(props) {
        super(props);
        this.handleSubmitButton = this.handleSubmitButton.bind(this);
        this.handleChangeKey = this.handleChangeKey.bind(this);
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.state = {
            assignee: '',
            projects: [],
            result: [],
            isLoading: true,
            isUpdating: false,
            pagination: {
                page: 0,
                count: 0,
                rowPerPage: 10
            },
            expanded: false,
            orgId: localStorage.getItem('backlogOrgId') ? localStorage.getItem('backlogOrgId') : '',
            apiKey: localStorage.getItem('backlogApiKey') ? localStorage.getItem('backlogApiKey') : '',
            assigneeId: localStorage.getItem('backlogAssigneeId'),
        };
    }

    componentDidMount() {
        if (localStorage.getItem('backlogOrgId') && localStorage.getItem('backlogApiKey')) {
            this.setState({isUpdating: true});
        }
        this.loadData(this.state.pagination.page).then(r => {

        });
        this.interval = setInterval(() => {
            if(!this.props.pauseInterval) {
                this.loadData(this.state.pagination.page, this.state.pagination.rowPerPage).then(r => {

                })
            }
        }, 60000);
    }

    handleChangePage = (event, newPage) => {
        this.loadData(newPage, this.state.pagination.rowPerPage).then(r => this.setState({}))
    }

    handleChangeRowsPerPage = (event) => {
        this.loadData(0, +event.target.value).then(r => {
        })
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleExpandClick() {
        const currentState = this.state.expanded;
        this.setState({
            expanded: !currentState
        })
    };

    async loadData(page = 0, rowPerPage = 10) {
        if (this.state.orgId && this.state.apiKey) {
            if (!this.state.assigneeId && this.state.apiKey) {
                await this.getAssigneeId(this.state.apiKey);
            }
            await axios.get(
                'https://' + this.state.orgId + '.backlog.com/api/v2/issues?apiKey=' + this.state.apiKey + '&assigneeId[]=' + this.state.assigneeId + '&count=' + rowPerPage + '&sort=dueDate&order=asc&statusId[]=1&statusId[]=2&statusId[]=100031&statusId[]=3&offset=' + (page * 10))
                .then(async response => {
                    this.setState({
                        isLoading: true,
                        isUpdating: true,
                    });
                    response.data = await this.organizeData(response.data);
                    await axios.get('https://' + this.state.orgId + '.backlog.com/api/v2/issues/count?apiKey=' + this.state.apiKey + '&assigneeId[]=' + this.state.assigneeId + '&statusId[]=1&statusId[]=2&statusId[]=100031&statusId[]=3')
                        .then(async res => {
                            this.setState({
                                assignee: response.data[0].assignee,
                                result: response.data,
                                isLoading: false,
                                isUpdating: false,
                                pagination: {
                                    count: res.data.count,
                                    page: page,
                                    rowPerPage: rowPerPage,
                                }
                            });
                        })
                        .catch(error=>{
                            console.error('There was an error!', error.message);
                        })
                })
                .catch(error => {
                    this.setState({
                        isLoading: false,
                        isUpdating: false,
                    });
                    console.error('There was an error!', error.message);
                });
        }
    }

    async getProject(projectId) {
        if (!this.state.projects.hasOwnProperty(projectId)) {
            let projects = this.state.projects;
            await axios.get(
                'https://' + this.state.orgId + '.backlog.com/api/v2/projects/' + projectId + '?apiKey=' + this.state.apiKey
            )
                .then(response => {
                    projects[projectId] =
                        <Link
                            target="_blank"
                            rel="noreferrer"
                            underline="none"
                            href={'https://' + this.state.orgId + '.backlog.com/projects/' + response.data.projectKey}
                            sx={{color: 'secondary.main'}}
                        >
                            {response.data.name}
                        </Link>;
                    this.setState({
                        projects: projects
                    })
                })
                .catch(error => {

                    console.error('There was an error!', error);
                });
        }

        return this.state.projects[projectId];
    }

    async organizeData(issues) {

        return await Promise.all(issues.map(async issue => {
            console.log(issue)
            return {
                id: issue.id,
                project: await this.getProject(issue.projectId).then(res => {
                    return res;
                }).catch(error => {

                    return error;
                }),
                link:
                    <Link
                        target="_blank"
                        rel="noreferrer"
                        underline="none"
                        href={'https://' + this.state.orgId + '.backlog.com/view/' + issue.issueKey}
                        sx={{color: 'secondary.main'}}
                    >
                        {issue.summary}
                    </Link>,
                subject: issue.summary,
                issue_type: issue.issueType.name,
                assignee: issue.assignee.nulabAccount.name,
                currentStatus: issue.status.name,
                priority: issue.priority.name,
                startDate: issue.startDate,
                dueDate: issue.dueDate,
                createdUser: (issue.createdUser.nulabAccount ? issue.createdUser.nulabAccount.name : 'N/A'),
            }
        })).then(result => {

            return result;
        }).catch(error => {
            console.log('API Error: ' + error.message)
        })
    }

    handleChangeKey(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmitButton(event) {
        this.setState({isUpdating: true});
        this.getAssigneeId(this.state.apiKey).then(r => {
            this.loadData(0, this.state.pagination.rowPerPage).then(r => {
                localStorage.setItem('backlogOrgId', this.state.orgId);
                localStorage.setItem('backlogApiKey', this.state.apiKey);
            })
        });
    }

    async getAssigneeId(val) {
        await axios.get(
            'https://' + this.state.orgId + '.backlog.com/api/v2/users/myself?apiKey=' + val)
            .then(response => {
                if (response.data) {
                    localStorage.setItem('backlogAssigneeId', response.data.id);
                    this.setState({
                        assigneeId: response.data.id
                    });
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    render() {
        return (
            <Card style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'start',
                flexDirection: 'column',
                transition: '0.3s',
                overflow: 'initial',
                background: '#ffffff'
            }}>
                <CardActions disableSpacing>
                    Backlog :: {this.state.assignee}
                    <ExpandMore
                        expand={this.state.expanded}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="show configuration"
                        size='small'
                    >Config<ExpandMoreIcon/>
                    </ExpandMore>
                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Box component="div" sx={{
                            '& .MuiTextField-root': {m: 1},
                        }} style={{paddingLeft: 10, paddingRight: 10}}>
                            <TextField style={{width: '15ch'}} label={'Org Id'} name='orgId' id='backlogOrgId'
                                       value={this.state.orgId}
                                       variant="standard" size="small" color="primary" onChange={this.handleChangeKey}/>
                            <TextField style={{width: '25ch'}} label={'API Key'} name='apiKey' id='backlogApiKey'
                                       value={this.state.apiKey}
                                       type={'password'}
                                       variant="standard" size="small"
                                       color="primary" onChange={this.handleChangeKey}/>
                            <Button variant="outlined" color='success' size="small"
                                    onClick={this.handleSubmitButton}>Submit</Button>
                        </Box>
                    </CardContent>
                </Collapse>
                <CardContent style={{padding: 1}}>
                    <IssueTable fontSize={this.props.fontSize} state={this.state} handleChangePage={this.handleChangePage} handleChangeRowsPerPage={this.handleChangeRowsPerPage}/>
                </CardContent>
            </Card>
        )
    }
}

export default BacklogIssueTable;