import React, {Component} from 'react';
import axios from "axios";
import IssueTable from "./TableComponent";
import {
    Box, Button,
    Card,
    CardActions,
    CardContent,
    Collapse,
    IconButton,
    Link,
    styled,
    TextField
} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function ExpandMoreIcon() {
    return <ArrowDownward/>;
}

class GitIssueTable extends Component {
    constructor(props) {
        super(props);
        this.handleChangeKey = this.handleChangeKey.bind(this);
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handleSubmitButton = this.handleSubmitButton.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.state = {
            assignee: '',
            projects: [],
            result: [],
            isLoading: true,
            isUpdating: false,
            pagination: {
                page: 0,
                count: 0,
                rowPerPage: 10
            },
            expanded: false,
            apiKey: localStorage.getItem('gitlabApiKey') ? localStorage.getItem('gitlabApiKey') : '',
            assigneeId: localStorage.getItem('gitlabAssigneeId'),
            username: localStorage.getItem('gitlabUsername') ? localStorage.getItem('gitlabUsername') : ''
        };
    }

    componentDidMount() {
        if (localStorage.getItem('gitlabApiKey') && localStorage.getItem('gitlabAssigneeId')) {
            this.setState({isUpdating: true});
        }
        this.loadData(this.state.pagination.page).then(r => {

        });
        this.interval = setInterval(() => {
            if(!this.props.pauseInterval) {
                this.loadData(this.state.pagination.page + 1, this.state.pagination.rowPerPage).then(r => {
                });
            }
        }, 60000);

    }

    handleChangePage = (event, newPage) => {
        this.loadData(newPage + 1, this.state.pagination.rowPerPage).then(r => {
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.loadData(1, +event.target.value).then(r => {
        })
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleExpandClick() {
        const currentState = this.state.expanded;
        this.setState({
            expanded: !currentState
        })
    };

    async loadData(page = 1, rowPerPage = 10) {
        if (this.state.apiKey && this.state.username) {
            if (!this.state.assigneeId && this.state.apiKey) {
                await this.getAssigneeId(this.state.username);
            }
            await axios.get(
                'https://gitlab.com/api/v4/issues?private_token=' + this.state.apiKey + '&assignee_id=' + this.state.assigneeId + '&state=opened&order_by=due_date&per_page=' + rowPerPage + '&scope=all&sort=asc&page=' + page)
                .then(async response => {
                    this.setState({
                        isLoading: true,
                        isUpdating: true,
                    });
                    response.data = await this.organizeData(response.data);
                    return response;
                })
                .then(response => {
                    return response;
                })
                .then(result => {
                    this.setState({
                        assignee: result.data[0].assignee,
                        result: result.data,
                        isLoading: false,
                        isUpdating: false,
                        pagination: this.organizeHeader(result.headers)
                    });
                })
                .catch(error => {
                    this.setState({
                        isLoading: false,
                        isUpdating: false,
                    });
                    console.error('There was an error!', error);
                });
        }
    }

    async getProject(projectId) {
        if (!this.state.projects.hasOwnProperty(projectId)) {
            let projects = this.state.projects;
            await axios.get(
                'https://gitlab.com/api/v4/projects/' + projectId + '?private_token=' + this.state.apiKey
            )
                .then(response => {
                    projects[projectId] = <Link
                        target="_blank"
                        rel="noreferrer"
                        underline="none"
                        href={response.data.web_url}
                        sx={{color: 'secondary.main'}}
                    >
                        {response.data.name}
                    </Link>;
                    this.setState({
                        projects: projects
                    })
                })
                .catch(error => {

                    console.error('There was an error!', error);
                });
        }

        return this.state.projects[projectId];
    }

    async organizeData(issues) {
        return await Promise.all(issues.map(async issue => {
            return {
                id: issue.id,

                project: await this.getProject(issue.project_id).then(res => {
                    return res;
                }).catch(error => {

                    return error;
                }),
                link: <Link
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                    href={issue.web_url}
                    sx={{color: 'secondary.main'}}
                >
                    {issue.title}
                </Link>,
                subject: issue.title,
                issue_type: issue.type,
                assignee: <Link
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                    href={issue.assignee.web_url}
                    sx={{color: 'purple'}}
                >
                    {issue.assignee.name}
                </Link>,
                currentStatus: issue.state,
                priority: issue.severity === 'UNKNOWN' ? '' : issue.severity,
                startDate: issue.created_at,
                dueDate: issue.due_date,
                createdUser: issue.author.name,
            }
        })).then(result => {

            return result;
        }).catch(error => {
            console.log('API Error: ' + error)
        })
    }

    organizeHeader(header) {
        return {
            count: parseInt(header['x-total']),
            page: parseInt(header['x-page']) - 1,
            rowPerPage: parseInt(header['x-per-page']),
        }
    }

    handleChangeKey(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmitButton(event) {
        this.setState({isUpdating: true});
        this.getAssigneeId(this.state.username).then(r => {
            this.loadData(0, this.state.pagination.rowPerPage).then(r => {
                localStorage.setItem('gitlabApiKey', this.state.apiKey);
                localStorage.setItem('gitlabUsername', this.state.username);
            })
        });
    }

    async getAssigneeId(value) {
        await axios.get(
            'https://gitlab.com/api/v4/users?username=' + value)
            .then(response => {
                if (response.data.length === 1) {
                    localStorage.setItem('gitlabAssigneeId', response.data[0].id);
                    this.setState({
                        assigneeId: response.data[0].id
                    });
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    isUpdating: false,
                });
                console.error('There was an error!', error);
            });
    }

    render() {
        return (
            <Card style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'start',
                flexDirection: 'column',
                transition: '0.3s',
                overflow: 'initial',
                background: '#ffffff'
            }}>
                <CardActions disableSpacing>
                    Gitlab :: {this.state.assignee}
                    <ExpandMore
                        expand={this.state.expanded}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="show configuration"
                        size='small'
                    >Config<ExpandMoreIcon/>
                    </ExpandMore>
                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Box component="div" sx={{
                            '& .MuiTextField-root': {m: 1},
                        }} style={{paddingLeft: 10, paddingRight: 10}}>
                            <TextField style={{width: '25ch'}} name='apiKey' label={'API Key'} type={'password'}
                                       value={this.state.apiKey} onChange={this.handleChangeKey} variant="standard"
                                       size="small" color="primary"/>
                            <TextField style={{width: '20ch'}} name='username' label={'Username'}
                                       value={this.state.username} onChange={this.handleChangeKey} variant="standard"
                                       size="small" color="primary"/>
                            <Button variant="outlined" color='error' size="small"
                                    onClick={this.handleSubmitButton}>Submit</Button>
                        </Box>
                    </CardContent>
                </Collapse>
                <CardContent style={{padding: 1}}>
                    <IssueTable fontSize={this.props.fontSize} state={this.state}
                                handleChangePage={this.handleChangePage}
                                handleChangeRowsPerPage={this.handleChangeRowsPerPage}/>
                </CardContent>
            </Card>
        )
    }
}

export default GitIssueTable;