import React, {Component} from 'react';
import GitIssueTable from "./GitListTableComponent";
import BacklogIssueTable from "./BacklogListTableComponent";
import {Grid} from "@mui/material";

class IssueBoard extends Component {
    render() {
        return (
            <Grid container alignItems="stretch" spacing={1}>
                {this.props.gitlabShow && (
                    <Grid item padding={0} md={this.props.backlogShow ? 6 : this.props.gitlabShow ? 12 : 0}>
                        <GitIssueTable id='gitlabBox' fontSize={this.props.fontSize} pauseInterval={this.props.pauseInterval}/>
                    </Grid>
                )}
                {this.props.backlogShow && (
                    <Grid item padding={0} md={this.props.gitlabShow ? 6 : this.props.backlogShow ? 12 : 0}>
                        <BacklogIssueTable id='backlogBox' fontSize={this.props.fontSize} pauseInterval={this.props.pauseInterval}/>
                    </Grid>
                )}
            </Grid>
        )
    }
}

export default IssueBoard;