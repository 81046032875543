import React from "react";
import {format} from "date-fns";
import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    styled,
    tableCellClasses, TablePagination, LinearProgress, Paper
} from "@mui/material";
import TablePaginationActions from "./PaginationComponent";


function IssueTable(props) {
    const {fontSize, state, handleChangePage,handleChangeRowsPerPage} = props;
    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontSize: fontSize + 'px',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: fontSize + 'px',
            textTransform: 'capitalize',
        },
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            textTransform: 'capitalize'
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
            textTransform: 'capitalize'
        },
    }));
    const isExpired = (date) => {
        const today = new Date();
        if (today > date) {
            return {
                color: 'red',
                fontWeight: 'bold'
            };
        }

        return {};
    };

    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={state.pagination.count}
                rowsPerPage={state.pagination.rowPerPage}
                page={state.pagination.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            >
            </TablePagination>
            <TableContainer>
                <Table stickyHeader sx={{minWidth: 600}} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Subject</StyledTableCell>
                            <StyledTableCell>Project</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Priority</StyledTableCell>
                            <StyledTableCell>Start Date</StyledTableCell>
                            <StyledTableCell>Due Date</StyledTableCell>
                            <StyledTableCell>Author</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.isUpdating ? (<StyledTableRow><StyledTableCell component="th" scope="row" colSpan={6}>
                            <LinearProgress color="secondary"/>
                        </StyledTableCell></StyledTableRow>) : ''}
                        {state.isLoading === false && state.result.map((issue) => (
                            <StyledTableRow
                                key={issue.id}
                            >
                                <StyledTableCell component="th" scope="row">
                                    {issue.link}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {issue.project}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {issue.currentStatus}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {issue.priority}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {issue.startDate ? format(new Date(issue.startDate), 'yyyy/MM/dd') : null}
                                </StyledTableCell>
                                <StyledTableCell style={isExpired(new Date(issue.dueDate))}>
                                    {issue.dueDate ? format(new Date(issue.dueDate), 'yyyy/MM/dd') : null}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {issue.createdUser}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={state.pagination.count}
                rowsPerPage={state.pagination.rowPerPage}
                page={state.pagination.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            >
            </TablePagination>
        </Paper>
    );
}

export default IssueTable;