import * as React from 'react';
import IssueBoard from './Component/IssueBoardComponent';
import IconImage from './logo.png';
import {PauseSharp, PlayArrowSharp} from '@mui/icons-material';

import {
    AppBar, Box,
    createTheme,
    FormControlLabel,
    IconButton, Link, Paper, Switch, TextField,
    ThemeProvider,
    Toolbar, Typography
} from "@mui/material";
import {red} from "@mui/material/colors";
import * as PropTypes from "prop-types";
import {useState} from "react";
import "./global"

const theme = createTheme({
    palette: {
        primary: {
            main: '#f3f3f3',
        },
        secondary: {
            main: '#21524e',
        },
        error: {
            main: red.A400,
        },
    },
});

function MuiAppBar(props) {
    return null;
}

MuiAppBar.propTypes = {
    elevation: PropTypes.number,
    position: PropTypes.string,
    children: PropTypes.node
};

function App() {
    const [fontSize, setFontSize] = useState(12);
    const [gitlabShow, setGitlabShow] = useState(true);
    const [backlogShow, setBacklogShow] = useState(true);
    const [pause, setPause] = useState(false);
    const handleChange = (event => {
        if (event.target.value === 'gitlab') {
            setGitlabShow(event.target.checked)
        }
        else if (event.target.value === 'backlog') {
            setBacklogShow(event.target.checked)
        }
        else if (event.target.name === 'fontSize') {
            setFontSize(event.target.value)
        }
    });
    const handlePause = (event => {
        setPause(!pause);
    });
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex'}}>
                <AppBar component="nav">
                    <Toolbar sx={{display: 'flex',justifyContent: 'space-between'}}>
                        <div>
                            <FormControlLabel
                                value="gitlab"
                                control={<Switch defaultChecked color="error" size="small"/>}
                                label={"GitLab"}
                                labelPlacement="end"
                                onChange={handleChange}
                            />
                            <FormControlLabel
                                value="backlog"
                                control={<Switch defaultChecked color="secondary" size="small"/>}
                                label="Backlog"
                                labelPlacement="end"
                                onChange={handleChange}
                            />
                            <IconButton onClick={handlePause} aria-label='Play or Pause API calling' size="small" color="secondary" >
                                {!pause && <PauseSharp />}
                                {pause && <PlayArrowSharp />}
                                {pause?'Play':'Pause'} API calling
                            </IconButton>
                        </div >
                        <Box>
                            <TextField name='fontSize' label={'Font Size'}
                                       value={fontSize} onChange={handleChange} variant="standard"
                                       size="small" color="primary"/>
                        </Box>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }} >
                            <Typography>Issue Board by <Link
                                target="_blank"
                                rel="noreferrer"
                                underline="none"
                                href='https://www.export-japan.co.jp/member/eather/'
                                color={'success'}
                            >Iftekhar Ahmed Eather
                            </Link></Typography>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <Toolbar/>
            <Paper square sx={{ pb: '50px' }}>
                <IssueBoard gitlabShow={gitlabShow} backlogShow={backlogShow} fontSize={fontSize} pauseInterval={pause}/>
            </Paper>
            <AppBar color="primary" size='small' sx={{ top: 'auto', bottom: 0 }}>
                <img
                    src={IconImage}
                    alt='Export Japan Inc.'
                    style={{width: '100px', margin: '0 auto', padding: '10px'}}
                    loading="lazy"
                />
            </AppBar>
        </ThemeProvider>
    );
}

export default App;
